import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../shared/services/theme-service';
import { AddEsgConferenceEntryRequest, ApiError, DictionaryService, ESGConferenceService, EsgDictionaryValueDto, GetDictionaryListRequest, GetDictionaryListResponse, PublicEsgConferenceResponse } from '@ekowitryna/ekowitryna-esg-calculator-angular-api';
import { last, lastValueFrom } from 'rxjs';
import { DialogHelper } from '../../shared/helpers/dialog-helper';
import { MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-conference-form',
  templateUrl: './conference-form.component.html',
  providers: [DialogHelper]
})
export class ConferenceFormComponent implements OnInit {
  @ViewChild('accessCodeInput') accessCodeInput!: ElementRef; // Dodano referencję do elementu

  sidebarVisible = false;
  form: FormGroup = new FormGroup({});
  transportOptions: any[] = [];
  loading: boolean = true;
  formLoading: boolean = false;
  errorLoadingDictionaries: boolean = false;  
  activeIndex: number = 0;
  conference: PublicEsgConferenceResponse | null = null;
  accessCode: string | undefined = undefined;
  accessCodeServerError: string | undefined = undefined;
  accessCodeLoading: boolean = false;
  showQrScanner: boolean = false;
  hasCameraPermission: boolean | undefined = undefined;
  formSent: boolean = false;

  accessCodeForm: FormGroup = new FormGroup({
    accessCode: new FormControl('', [Validators.required, Validators.minLength(8)])
  });

  steps: MenuItem[] = [];
  
  currentLang: string = 'pl';

  getDictionaryList!: GetDictionaryListRequest;
  vehicleTypesOptions: EsgDictionaryValueDto[] = [];
  fuelTypeOptions: EsgDictionaryValueDto[] = [];
  commercialInformationOptions: string[] = [];
  errorMessages: string[] | null = null;

  constructor(
    public translate: TranslateService,
    public themeService: ThemeService,
    private dictionaryService: DictionaryService,
    public dialogHelper: DialogHelper,
    private cd: ChangeDetectorRef,
    private conferenceService: ESGConferenceService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {
    this.currentLang = this.translate.currentLang;
    this.initDictionaries();
  }

  nextStep() {
    this.activeIndex++;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const accessCode = params['access_code'];
      if (accessCode) {
        this.accessCodeForm.get('accessCode')?.setValue(accessCode);
        this.accessCode = accessCode;
        this.getConference();
      }
    });


    this.translate.getTranslation(this.translate.currentLang).subscribe(() => {
      this.translate.get('form.conference.step1').subscribe((res: string) => {
        this.steps = [
          {
            label: res,
          },
          {
            label: this.translate.instant('form.conference.step2')
          },
          {
            label: this.translate.instant('form.conference.step3')
          }
        ];
      });
    });
  }

  submitAccessCode() {
    if(!this.accessCodeForm.valid) return;
    this.accessCodeLoading = true;
    this.getConference();
  }

  getConference() {
    lastValueFrom(this.conferenceService.getConferenceByAccessCode(this.accessCodeForm.get('accessCode')?.value))
    .then((res: PublicEsgConferenceResponse) => {
      this.conference = res;
      this.accessCode = this.accessCodeForm.get('accessCode')?.value;
    })
    .catch(err => {
      this.accessCode = undefined;
      if (err && err.error && 'debugMessage' in err.error) {
        const apiError = err.error as ApiError;
        this.accessCodeServerError = apiError.message ?? this.translate.instant('service.error');
      } else {
        this.accessCodeServerError = this.translate.instant('service.error')
      }

      this.accessCodeInput.nativeElement.focus();
    })
    .finally(() => {
      this.accessCodeLoading = false;
    });
  }

  scanQrCode() {
    this.showQrScanner = true;
  }

  handleQrCodeResult(data: string) {
    // get from url access_code query param
    const url = new URL(data);
    const accessCode = url.searchParams.get('access_code');
    if (accessCode) {
      this.accessCodeForm.get('accessCode')?.setValue(accessCode);
      this.getConference();
      this.messageService.add({severity:'success', summary:'Success', detail:'Access code scanned successfully'});
    } else {
      this.handleQrCodeError(data);
    }
  }

  handleQrCodeError(data?: string){
    this.messageService.add({severity:'error', summary:'Error', detail:'QR code not recognized'});
    this.showQrScanner = false;
  }

  handlePermissionResponse(permission: boolean) {
    this.hasCameraPermission = permission;
  }

  closeScanner() {
    this.showQrScanner = false;
  }


  previousStep() {
    if(this.activeIndex === 0) return;
    this.activeIndex--;
  }

  initDictionaries() {
    this.getDictionaryList = {
      dictionaryKeys: ['EsgConferenceVehicleType', 'VehicleFuelType'] 
    };

    lastValueFrom(this.dictionaryService.getList(this.getDictionaryList))
      .then((res: GetDictionaryListResponse) => {
        for (let dictionary of res.dictionaries) {
          if (dictionary.key === 'EsgConferenceVehicleType') {
            this.vehicleTypesOptions = dictionary.values;
          }
          if (dictionary.key === 'VehicleFuelType') {
            this.fuelTypeOptions = dictionary.values; 
          }
        }
        this.loading = false;
        this.initForm();
      })
      .catch(err => {
        this.errorLoadingDictionaries = true;  
        this.loading = false;
      });
  }

  initForm() {
    this.form = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      vehicleType: new FormControl(null, Validators.required),
      fuelType: new FormControl(null),
      participants: new FormControl(1, [Validators.required, Validators.min(1)]),
      distance: new FormControl('', [Validators.required, Validators.min(1)]),
      companyName: new FormControl('', Validators.required),
      companyNip: new FormControl(''),
      phoneNumber: new FormControl(''),
      acceptTos: new FormControl(false, Validators.requiredTrue),
      acceptMarketing: new FormControl(false),
      acceptNewsletter: new FormControl(false),
      acceptContactPhone: new FormControl(false),
      acceptContactEmail: new FormControl(false)
    });

    this.form.controls.vehicleType.valueChanges.subscribe((value: string) => {
      this.updateValidators(value);
    });
  }

  submit() {
    this.errorMessages = null;
    this.formLoading = true;
    
    if(!this.form.valid) {
      this.formLoading = false;
      return;
    }

    const request: AddEsgConferenceEntryRequest = {
      accessCode: this.accessCode ?? '',
      companyName: this.form.get('companyName')?.value,
      companyNip: this.form.get('companyNip')?.value,
      distance: this.form.get('distance')?.value,
      email: this.form.get('email')?.value,
      firstName: this.form.get('firstName')?.value,
      fuelType: this.isCar() ? this.form.get('fuelType')?.value : undefined,
      lastName: this.form.get('lastName')?.value,
      participants: this.form.get('participants')?.value,
      phoneNumber: this.form.get('phoneNumber')?.value,
      vehicleType: this.form.get('vehicleType')?.value,
      acceptTos: this.form.get('acceptTos')?.value,
      acceptPrivacyPolicy: this.form.get('acceptTos')?.value,
      acceptMarketing: this.form.get('acceptMarketing')?.value,
      acceptNewsletter: this.form.get('acceptNewsletter')?.value,
      acceptCommercialInformation: []
    }; 

    if(this.form.get('acceptContactPhone')?.value) {
      request.acceptCommercialInformation?.push(AddEsgConferenceEntryRequest.AcceptCommercialInformationEnum.Phone);
    }

    if(this.form.get('acceptContactEmail')?.value) {
      request.acceptCommercialInformation?.push(AddEsgConferenceEntryRequest.AcceptCommercialInformationEnum.Mail);
    }

    lastValueFrom(this.conferenceService.addEntryToConference(request))
      .then(() => {
        this.formSent = true;
      })
      .catch(err => {
        this.errorMessages = err.error.errors.map((e: any) => e.message);
      })
      .finally(() => {
        this.formLoading = false;
      })
  }

  isCar(){
    return this.form.get('vehicleType')?.value === 'CAR';
  }


  updateValidators(vehicleType: string) {
    this.cd.detectChanges();
    const fuelTypeControl = this.form.get('fuelType');
    const peopleCountControl = this.form.get('participants');

    if (vehicleType === 'CAR') {
      fuelTypeControl?.setValidators(Validators.required); 
      peopleCountControl?.setValidators([Validators.required, Validators.min(1)]);
    } else {
      fuelTypeControl?.clearValidators();
      peopleCountControl?.clearValidators();
    }

    fuelTypeControl?.updateValueAndValidity();
    peopleCountControl?.updateValueAndValidity();
  }

  toggleNightMode() {
    this.themeService.toggleDarkMode();
  }

  getErrorMessage(field: string): string {
    const control = this.form.get(field);
    if (control) {
      if (control.hasError('required')) return this.translate.instant('form.conference.requiredField');
      if (control.hasError('requiredTrue')) return this.translate.instant('form.conference.requiredField');
      if (control.hasError('email')) return this.translate.instant('form.conference.invalidEmail');
      if (control.hasError('min')) return this.translate.instant('form.conference.minValue');
    }
    return '';
  }
}
