<div class="bg-bunker-500 shadow-sm flex justify-between items-center p-2 lg:p-6 border-b border-gray-200 dark:border-gray-800 z-[40]">
  <div class="hidden lg:flex items-center gap-4">
    <app-app-panel></app-app-panel>
    <img src="../assets/img/logo_large.svg" class="mx-auto max-h-10" alt="BDO mobile" />
    <p-chip label="ESG" severity="info" class="text-xs"></p-chip>
  </div>
  <!-- Menu button for mobile -->
  <button (click)="sidebarVisible = true" class="menu-button lg:hidden">
    <i class="pi pi-bars text-xl"></i>
  </button>
  <!-- Logo centered for mobile, hidden on desktop -->
  <div class="flex-grow lg:flex-grow-0 lg:hidden text-center">
    <img src="../assets/img/logo_sygnet.svg" class="w-16 h-16 mx-auto" alt="BDO mobile" />
  </div>
  <!-- Spacer to push icons to the right on desktop, hidden on mobile -->
  <div class="hidden lg:flex flex-grow"></div>
  <!-- Icons for settings, notifications, and night mode (desktop only) -->
  <div class="flex items-center">
    <p-button pRipple type="button" [icon]="themeService.isDarkMode ? 'pi pi-sun' : 'pi pi-moon'" class="mx-2" (onClick)="toggleNightMode()" [pTooltip]="themeService.isDarkMode ? 'Zmień na tryb jasny' : 'Zmień na tryb ciemny'" tooltipPosition="top" [text]="true"></p-button>
  </div>
  <app-language-button ></app-language-button>
</div>

<div *ngIf="formSent" class="h-screen flex items-center justify-center">
  <div class="text-center p-8rounded-lg">
    <h2 class="text-2xl font-bold mb-4">{{ 'form.conference.thankYou' | translate }}</h2>
    <p>Dziękujemy za wzięcie udziału w konferencji oraz wypełnienie formularza. Twoje zgłoszenie zostało zarejestrowane. Zapraszam do odwiedzenia naszej strony internetowej.</p>
    <p class="text-lg">{{ 'form.conference.visit' | translate }} <a href="https://ekowitryna.pl" target="_blank" class="text-blue-500 underline">ekowitryna.pl</a></p>
  </div>
</div>

<div *ngIf="!accessCode" class="flex mt-10 justify-center h-screen">
  <form [formGroup]="accessCodeForm" (ngSubmit)="submitAccessCode()" class="text-center">
    <div *ngIf="!accessCode" class="mb-4 flex flex-col gap-4 items-center">
      <label for="accessCode" class="block text-2xl font-bold mb-2">Podaj kod konferencji:</label>
      <p class="text-lg mb-2">Wpisz kod dostępu do konferencji dostarczony przez organizatora.</p>
      <p-inputOtp #accessCodeInput id="accessCode" formControlName="accessCode" [length]="8" [autofocus]="true" [disabled]="accessCodeLoading"></p-inputOtp>
      <p class="text-red-600" *ngIf="accessCodeServerError">{{ accessCodeServerError }}</p>
      <small *ngIf="accessCodeForm.get('accessCode')?.touched && accessCodeForm.get('accessCode')?.invalid" class="text-red-600">{{ getErrorMessage('accessCode') }}</small>
      <div class="flex gap-4">
        <!-- QR Code scanner -->
        <p-button label="Skanuj kod QR" icon="pi pi-camera" class="p-button-outlined" (click)="scanQrCode()"></p-button>
        <p-button label="Dalej" icon="pi pi-arrow-right" type="submit" [loading]="accessCodeLoading" [disabled]="!accessCodeForm.valid" ></p-button>
      </div>
      <div *ngIf="showQrScanner" class="mt-4 flex flex-col gap-2">
        <p *ngIf="hasCameraPermission === false" class="text-red-600">Brak uprawnień do kamery. Spróbuj ponownie i zezwól na dostęp do kamery.</p>
        <zxing-scanner (scanSuccess)="handleQrCodeResult($event)" (scanError)="handleQrCodeError()" (permissionResponse)="handlePermissionResponse($event)"></zxing-scanner>
        <p-button label="Zamknij" icon="pi pi-times" class="p-button-outlined" (onClick)="closeScanner()"></p-button>
      </div>
      </div>
  </form>
</div>


<div class="card flex w-full justify-center items-center h-screen" *ngIf="loading">
  <p-progressSpinner ariaLabel="loading"/>
</div> 

<div class="min-h-screen flex flex-col">
  <div *ngIf="!loading && conference && !formSent" class="flex-grow">
    <p-steps *ngIf="conference && !formSent" [model]="steps" [(activeIndex)]="activeIndex" styleClass="mt-16 mb-16 hidden md:block"></p-steps>
  
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div *ngIf="activeIndex === 0">
        <!-- Welcome Screen -->
        <div class="flex flex-col items-center justify-center mt-6">
          <h1 class="text-2xl font-bold">{{ 'form.conference.welcome' | translate }}</h1>
          <p class="text-center">{{ 'form.conference.welcomeMessage' | translate }}</p>
          <p-button label="{{ 'form.conference.start' | translate }}" icon="pi pi-arrow-right" (click)="nextStep()" styleClass="mt-4"></p-button>
        </div>
      </div>
  
      <div class="container px-4 mx-auto" *ngIf="activeIndex === 1">
        <!-- Personal Data Section -->
         <h2 class="text-lg font-semibold">{{ 'form.conference.step2' | translate }}</h2>
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 dark:bg-gray-900 bg-white text-black dark:text-white rounded-md ">
          <div class="flex flex-col">
            <label for="firstName" class="font-semibold">{{ 'form.conference.firstName' | translate }}<span *ngIf="dialogHelper.isRequired('firstName', form)" class="p-1 text-red-600">*</span></label>
            <input pInputText formControlName="firstName" class="w-full dark:bg-gray-800 dark:text-white"/>
            <small *ngIf="form.get('firstName')?.touched && form.get('firstName')?.invalid" class="text-red-600">{{ getErrorMessage('firstName') }}</small>
          </div>
          
          <div class="flex flex-col">
            <label for="lastName" class="font-semibold">{{ 'form.conference.lastName' | translate }}<span *ngIf="dialogHelper.isRequired('lastName', form)" class="p-1 text-red-600">*</span></label>
            <input pInputText formControlName="lastName" class="w-full dark:bg-gray-800 dark:text-white"/>
            <small *ngIf="form.get('lastName')?.touched && form.get('lastName')?.invalid" class="text-red-600">{{ getErrorMessage('lastName') }}</small>
          </div>
          
          <div class="flex flex-col">
            <label for="email" class="font-semibold">{{ 'form.conference.email' | translate }}<span *ngIf="dialogHelper.isRequired('email', form)" class="p-1 text-red-600">*</span></label>
            <input pInputText formControlName="email" type="email" class="w-full dark:bg-gray-800 dark:text-white"/>
            <small *ngIf="form.get('email')?.touched && form.get('email')?.invalid" class="text-red-600">{{ getErrorMessage('email') }}</small>
          </div>
          
          <div class="flex flex-col">
            <label for="phoneNumber" class="font-semibold">{{ 'form.conference.phoneNumber' | translate }}<span *ngIf="dialogHelper.isRequired('phoneNumber', form)" class="p-1 text-red-600">*</span></label>
            <input pInputText formControlName="phoneNumber" class="w-full dark:bg-gray-800 dark:text-white"/>
            <small *ngIf="form.get('phoneNumber')?.touched && form.get('phoneNumber')?.invalid" class="text-red-600">{{ getErrorMessage('phoneNumber') }}</small>
          </div>
          
          <div class="flex flex-col">
            <label for="companyName" class="font-semibold">{{ 'form.conference.companyName' | translate }}<span *ngIf="dialogHelper.isRequired('companyName', form)" class="p-1 text-red-600">*</span></label>
            <input pInputText formControlName="companyName" class="w-full dark:bg-gray-800 dark:text-white"/>
            <small *ngIf="form.get('companyName')?.touched && form.get('companyName')?.invalid" class="text-red-600">{{ getErrorMessage('companyName') }}</small>
          </div>
          
          <div class="flex flex-col">
            <label for="companyNip" class="font-semibold">{{ 'form.conference.companyNip' | translate }}<span *ngIf="dialogHelper.isRequired('companyNip', form)" class="p-1 text-red-600">*</span></label>
            <input pInputText formControlName="companyNip" class="w-full dark:bg-gray-800 dark:text-white"/>
            <small *ngIf="form.get('companyNip')?.touched && form.get('companyNip')?.invalid" class="text-red-600">{{ getErrorMessage('companyNip') }}</small>
          </div>
        </div>
        <div class="flex justify-end mt-8 mb-8 container mx-auto">
          <p-button label="{{ 'form.conference.next' | translate }}" icon="pi pi-arrow-right" (click)="nextStep()"></p-button>
        </div>
      </div>
  
      <div class="container px-4 mx-auto" *ngIf="activeIndex === 2">
        <!-- Main Form Section -->
        <h2 class="text-lg font-semibold">{{ 'form.conference.step3' | translate }}</h2>
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 dark:bg-gray-900 bg-white text-black dark:text-white rounded-md ">
          <div class="flex flex-col">
            <label for="vehicleType" class="font-semibold">{{ 'form.conference.vehicleType' | translate }}<span *ngIf="dialogHelper.isRequired('vehicleType', form)" class="p-1 text-red-600">*</span></label>
            <p-dropdown 
              formControlName="vehicleType"
              [options]="vehicleTypesOptions"
              placeholder="{{ 'form.conference.vehicleType.placeholder' | translate }}"
              appendTo="body"
              class="!w-full dark:bg-gray-800"
              styleClass="!w-full"
              [optionLabel]='"labels."+ currentLang' 
              optionValue="key"
            ></p-dropdown>
            <small *ngIf="form.get('vehicleType')?.touched && form.get('vehicleType')?.invalid" class="text-red-600">{{ getErrorMessage('vehicleType') }}</small>
          </div>
          
          <div *ngIf="isCar()" class="flex flex-col">
            <label for="fuelType" class="font-semibold">{{ 'form.conference.fuelType' | translate }}<span *ngIf="dialogHelper.isRequired('fuelType', form)" class="p-1 text-red-600">*</span></label>
            <p-dropdown 
              formControlName="fuelType"
              [options]="fuelTypeOptions"
              placeholder="{{ 'form.conference.fuelType.placeholder' | translate }}"
              appendTo="body"
              class="!w-full dark:bg-gray-800"
              styleClass="!w-full"
              [optionLabel]='"labels."+ currentLang' 
              optionValue="key"
            ></p-dropdown>
            <small *ngIf="form.get('fuelType')?.touched && form.get('fuelType')?.invalid" class="text-red-600">{{ getErrorMessage('fuelType') }}</small>
          </div>
          
          <div class="flex flex-col">
            <label for="distance" class="font-semibold">{{ 'form.conference.distance' | translate }}<span *ngIf="dialogHelper.isRequired('distance', form)" class="p-1 text-red-600">*</span></label>
            <input pInputText formControlName="distance" type="number" class="w-full dark:bg-gray-800 dark:text-white"/>
            <small *ngIf="form.get('distance')?.touched && form.get('distance')?.invalid" class="text-red-600">{{ getErrorMessage('distance') }}</small>
          </div>
          
          <div class="flex flex-col">
            <label for="participants" class="font-semibold">{{ 'form.conference.participants' | translate }}<span *ngIf="dialogHelper.isRequired('participants', form)" class="p-1 text-red-600">*</span></label>
            <p-inputNumber formControlName="participants" type="number" styleClass="!w-full dark:bg-gray-800 dark:text-white" [min]="0" [max]="100"/>
            <small *ngIf="form.get('participants')?.touched && form.get('participants')?.invalid" class="text-red-600">{{ getErrorMessage('participants') }}</small>
          </div>
        </div>
  
        <!-- Consent Section -->
        <div class="flex flex-col gap-8 md:gap-4 mt-8">
          <div class="flex flex-col">
            <div>
              <p-checkbox formControlName="acceptTos" binary="true"></p-checkbox>
              <label onclick="document.querySelector('[formControlName=acceptTos] input').click()">
                <span class="ml-2" [innerHTML]="'form.conference.acceptTos' | translate"></span>
                <span *ngIf="dialogHelper.isRequired('acceptTos', form)" class="p-1 text-red-600">*</span>
              </label>
            </div>
            <small *ngIf="form.get('acceptTos')?.touched && form.get('acceptTos')?.invalid" class="text-red-600">{{ getErrorMessage('acceptTos') }}</small>
          </div>
          
          <div class="flex flex-col">
            <p-checkbox formControlName="acceptMarketing" binary="true" label="{{ 'form.conference.acceptMarketing' | translate }}"></p-checkbox>
            <small *ngIf="form.get('acceptMarketing')?.touched && form.get('acceptMarketing')?.invalid" class="text-red-600">{{ getErrorMessage('acceptMarketing') }}</small>
          </div>
  
          <div class="flex flex-col">
            <p-checkbox formControlName="acceptNewsletter" binary="true" label="{{ 'form.conference.acceptNewsletter' | translate }}"></p-checkbox>
            <small *ngIf="form.get('acceptNewsletter')?.touched && form.get('acceptNewsletter')?.invalid" class="text-red-600">{{ getErrorMessage('acceptNewsletter') }}</small>
          </div>
  
          
          
         <div class="flex flex-col gap-2">
          <span class="font-semibold">
            {{ "form.conference.acceptContact" | translate }} 
          </span>
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <p-checkbox formControlName="acceptContactPhone" binary="true" label="{{ 'form.conference.acceptContactPhone' | translate }}"></p-checkbox>
              <small *ngIf="form.get('acceptContactPhone')?.touched && form.get('acceptContactPhone')?.invalid" class="text-red-600">{{ getErrorMessage('acceptContactPhone') }}</small>
            </div>
            
            <div class="flex flex-col gap-2">
              <p-checkbox formControlName="acceptContactEmail" binary="true" label="{{ 'form.conference.acceptContactEmail' | translate }}"></p-checkbox>
              <small *ngIf="form.get('acceptContactEmail')?.touched && form.get('acceptContactEmail')?.invalid" class="text-red-600">{{ getErrorMessage('acceptContactEmail') }}</small>
            </div>
          </div>
         </div>
        </div>
  
        <!-- error -->
        <app-error-dialog [errorMessages]="errorMessages"></app-error-dialog>
        <div class="flex justify-between mt-8 mb-4 container mx-auto">
          <p-button label="{{ 'form.conference.previous' | translate }}" icon="pi pi-arrow-left" (click)="previousStep()"></p-button>
          <p-button label="{{ 'common.buttons.add' | translate }}" icon="pi pi-plus" severity="success" type="submit" [disabled]="!form.valid"></p-button>
        </div>
      </div>
    </form>
  </div>
  <app-footer class="mt-auto"></app-footer>
</div>
